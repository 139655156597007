import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 14" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 14</h1>
      <p>Happy Monday!</p>
      <p>Takk for en fin natt, kjære mamma! Jeg er den første til å forstå om du er litt trøtt, fordi jeg kjenner jo på den følelsen hver våkne time!</p>
      <p>I dag fikk du en stor sløyfe, som du gjerne må prøve på meg om du vil. Og sånn leppeting som pappa har måtte kjøpe til deg tidligere. Han sa det kunne være en fin ting å putte i kalenderen, så da fikset jeg det.</p>
      <p>I dag blir det spennende med fysio - gleder meg! Jeg skal prøve å vise hvor sterk jeg er! Tihiihihihi!</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke
